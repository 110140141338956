import * as React from "react";
import { gsap } from "gsap";

import { Layout } from "../../pages/darkmode";
import PasswordForm from "./password-form";

export default function Gate({ isUnlocked, onUnlocked }) {
  const ref = React.useRef(null);
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    const overflow = isUnlocked ? "visible" : "hidden";
    document.body.setAttribute("style", `overflow:${overflow}`);

    if (isUnlocked && !done) {
      const element = ref.current;
      const $content = element.querySelector("#gate-content");
      const $fade = element.querySelector("#gate-block");
      gsap.set($content, {
        y: "0%",
      });
      gsap.set($fade, { opacity: 1 });
      gsap.to($content, {
        y: "-100%",
        duration: 0.75,
      });
      gsap.to($fade, {
        opacity: 0,
        duration: 3,
        ease: "power1.out",
        onComplete: function () {
          setDone(true);
        },
      });
    }
  }, [done, isUnlocked]);

  if (done) {
    return null;
  }
  return (
    <div
      className="fixed -inset-0 z-50 overflow-scroll pointer-events-none"
      ref={ref}
    >
      <div
        id="gate-content"
        className="fixed -inset-0 z-50 overflow-scroll pointer-events-auto"
      >
        <Layout>
          <div className="content-container-max text-center flex flex-grow items-center">
            <div className="w-full mb-24">
              <h3 className="text-3xl md:text-5xl lg:text-8xl leading-tight font-thin">
                Ready to Lift the Veil?
              </h3>
              <p className="text-md md:text-2xl mt-5 lg:mt-8">
                Enter your access code here
              </p>
              <PasswordForm onCompleted={onUnlocked} />
            </div>
          </div>
        </Layout>
      </div>
      <div id="gate-block" className="fixed -inset-0 z-40 bg-black" />
    </div>
  );
}
