import React, { useState } from "react";
import eyeSvg from "../../images/darkmode/eye.svg";

const INPUT_STYLES =
  "rounded w-full bg-black text-white-900 text-md sm:text-sm border-solid border border-white-300 px-5 py-3 placeholder-white-300 md:py-4 md:text-lg md:pr-12";

const DARKNESS_CODE = "DARKMODE";

export default function PasswordForm({ onCompleted }) {
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function handleCode(e) {
    if (codeError) {
      setCodeError(false);
    }
    setCode(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (code.toUpperCase() !== DARKNESS_CODE) {
      setCodeError(true);
      return;
    }

    onCompleted();
  }

  return (
    <form
      className="mt-8 md:mt-12 pt-1 md:w-6/12 md:px-3 mx-auto lg:w-4/12"
      onSubmit={handleSubmit}
    >
      <p className="relative">
        <input
          className={`${INPUT_STYLES} ${codeError ? "text-red-500" : ""}`}
          name="code"
          onChange={handleCode}
          placeholder="Enter your code"
          type={showPassword ? "text" : "password"}
        />
        <button
          className="appearance-none"
          type="button"
          onClick={() => setShowPassword(!showPassword)}
        >
          <img
            className="absolute right-5 top-1/2 transform -translate-y-1/2"
            src={eyeSvg}
          />
        </button>
      </p>
      {codeError && (
        <span className="block text-left text-sm text-red-500 mt-4 md:text-lg">
          The code is incorrect. Please try again.
        </span>
      )}

      <div className="relative my-10">
        <button
          type="submit"
          className="w-full bg-white-900 text-black text-center inline-block text-xs px-8 py-2.5 leading-6 uppercase rounded-sm font-bold sm:transition-all sm:hover:bg-opacity-80 md:text-sm md:py-5 md:text-sm"
        >
          Submit
        </button>
      </div>
    </form>
  );
}
