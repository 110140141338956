import * as React from "react";
import { track } from "../../utils/analytics";

const REMAINING_INVENTORY = "Pre-order";
const SHOP_URL = "https://liveouter.com/early-access";

export default function ShopNow() {
  return (
    <div>
      <p className="mt-5 text-lg mb-8 md:mb-12 md:mt-12 xl:text-xl">
        <a
          onClick={() => {
            track("Light Mode Shop Now", { location: "Body" });
          }}
          href={SHOP_URL}
          className="bg-white-900 text-black text-center inline-block text-xs px-14 py-2.5 leading-6 uppercase rounded-sm font-bold sm:transition-all sm:hover:bg-opacity-80 md:text-sm md:py-5 md:text-sm"
        >
          Order Now
        </a>
      </p>
    </div>
  );
}

export function ShopNowHeader() {
  return (
    <div id="shop-now-header" className="flex items-center">
      <p className="text-sm mr-6 text-right sm:text-lg md:text-left">
        {REMAINING_INVENTORY} now <br className="block md:hidden" />
        available
      </p>
      <p>
        <a
          onClick={() => {
            track("Light Mode Shop Now", { location: "Sticky Header" });
          }}
          href={SHOP_URL}
          className="bg-white-900 text-black text-center inline-block text-xs px-5 py-2.5 leading-6 uppercase rounded-sm font-bold md:px-14 sm:transition-all sm:hover:bg-opacity-80 md:text-sm md:py-5 md:text-sm"
        >
          Order Now
        </a>
      </p>
    </div>
  );
}
