import * as React from "react";
import { graphql, Link } from "gatsby";

import outerLogoSvg from "../../images/darkmode/outer-logo.svg";

import "../../components/darkmode/index.scss";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Hero from "../../components/darkmode/hero";
import WordCycle from "../../components/darkmode/word-cycle";
import ValuePropDisplay from "../../components/darkmode/value-prop-display";
import BeforeAfterCompare from "../../components/darkmode/before-after-compare";
import ImageRollout from "../../components/darkmode/image-rollout";
import DarknessGallery from "../../components/darkmode/darkness-gallery";
import Gate from "../../components/darkmode/gate";
import GetYours from "../../components/darkmode/get-yours";
import { ShopNowHeader } from "../../components/darkmode/shop-now";
import { useFunnelTracking } from "../../utils/analytics";

function Spacer() {
  return <div className="mb-36 md:mb-56" />;
}

export default function DarkModePage({ data }) {
  const year = new Date().getFullYear();
  useFunnelTracking("Light Mode Landing Page");

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  const [unlocked, setUnlocked] = React.useState(false);
  const contentOpacity = unlocked ? 1 : 0;

  function handleUnlock() {
    window.scrollTo(0, 0);
    setAnimationEnabled(true);
    setUnlocked(true);

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 300);
  }

  const ref = React.useRef(null);

  const [animationEnabled, setAnimationEnabled] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (animationEnabled) {
      const element = ref.current;
      const $mainNav = element.querySelector("#main-nav");
      ScrollTrigger.create({
        trigger: element,
        start: "0 top",
        end: "bottom bottom",
        immediateRender: false,
        toggleClass: "sticky-nav",
        onEnterBack: () => {
          gsap.set($mainNav, {
            backgroundColor: "",
          });
        },
        onLeave: () => {
          gsap.set($mainNav, {
            backgroundColor: "rgba(0, 0, 0, 1)",
          });
        },
      });
    }
  }, [animationEnabled]);

  return (
    <div className="dark-mode-page">
      <Gate isUnlocked={unlocked} onUnlocked={handleUnlock} />
      <div style={{ opacity: contentOpacity }} ref={ref}>
        <div className="flex flex-col min-h-screen bg-black text-white-900">
          <nav
            id="main-nav"
            className="fixed bg-black bg-opacity-0 top-0 inset-x-0 z-20 sm:py-5 sm:pt-10 overflow-hidden"
          >
            <div className="content-container-max pb-10 sm:pb-0">
              <div
                id="animated-nav"
                className="animated-nav flex justify-between items-center transform translate-x-1/2"
              >
                <Link
                  to="/dark-mode"
                  id="animated-outer-logo"
                  className="outer-logo"
                >
                  <img src={outerLogoSvg} />
                </Link>
                <ShopNowHeader />
              </div>
            </div>
          </nav>
          <Hero animationEnabled={animationEnabled} />
          <div className="md:mt-20" />
          <WordCycle
            animationEnabled={animationEnabled}
            fluidImage={data.frame.childImageSharp.fluid}
          />
          <Spacer />
          <ValuePropDisplay
            animationEnabled={animationEnabled}
            valueProps={[
              {
                title: "Built to last",
                desc: "Aluminum is weather-resistant and will never rust, decay, or loosen",
              },
              {
                title: "Crafted with care",
                desc: "Expertly constructed with best-in-class welds to enhance sturdiness",
              },
              {
                title: "Promised protection",
                desc: "Finished with top-of-the-line powder coating guaranteed to last for 10+ years",
              },
            ]}
            fluidImage={data.chairWire.childImageSharp.fluid}
            imageRight={false}
          />
          <Spacer />
          <ValuePropDisplay
            animationEnabled={animationEnabled}
            valueProps={[
              {
                title: "Luxurious comfort",
                desc: "Multi-layer memory foam cushions offer luxurious comfort ",
              },
              {
                title: "Low-maintenance",
                desc: "Furniture arrives fully assembled in one easy-to-clean piece ",
              },
              {
                title: "Fully recyclable",
                desc: "The entire collection is 100% recyclable at the end of its (long) lifespan",
              },
            ]}
            imageRight
            fluidImage={data.chairWire2.childImageSharp.fluid}
          />
          <Spacer />
          <BeforeAfterCompare
            animationEnabled={animationEnabled}
            leftImage={data.alumGray.childImageSharp.fluid.src}
            rightImage={data.alumCream.childImageSharp.fluid.src}
          />
          <Spacer />
          <ImageRollout animationEnabled={animationEnabled} />
          <Spacer />
          <DarknessGallery data={data} animationEnabled={animationEnabled} />
          <div id="last-element">
            <Spacer />
            <GetYours animationEnabled={animationEnabled} />
            <footer className="mt-auto py-10">
              <div className="content-container-max flex flex-wrap justify-between items-center content-center">
                <p className="text-sm text-white-300 order-2 sm:order-1">
                  Outer&copy; {year}
                </p>
                <p className="text-sm order-1 text-right">
                  <a href="https://liveouter.com/privacy">Privacy Policy</a>
                  <a className="ml-5" href="https://liveouter.com/terms">
                    Terms of Use
                  </a>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    frame: file(relativePath: { eq: "darkmode/frame.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3016, maxHeight: 1124) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    chairWire: file(relativePath: { eq: "darkmode/photo-chair-wire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 935, maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    chairWire2: file(relativePath: { eq: "darkmode/photo-chair-wire-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 935, maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    alumCream: file(relativePath: { eq: "darkmode/alum-cream.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1390, maxHeight: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    alumGray: file(relativePath: { eq: "darkmode/alum-gray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1390, maxHeight: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery1: file(relativePath: { eq: "darkmode/gallery/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380, maxHeight: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery2: file(relativePath: { eq: "darkmode/gallery/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380, maxHeight: 2070) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery3: file(relativePath: { eq: "darkmode/gallery/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1580, maxHeight: 2368) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery4: file(relativePath: { eq: "darkmode/gallery/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1580, maxHeight: 1054) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery5: file(relativePath: { eq: "darkmode/gallery/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380, maxHeight: 776) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery6: file(relativePath: { eq: "darkmode/gallery/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380, maxHeight: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
